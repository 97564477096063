<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="d-flex flex-row-fluid flex-column bgi-size-cover bgi-position-center bgi-no-repeat p-10 p-sm-30 bg-white"
    >
      <div class="d-flex flex-column-fluid flex-center">
        <!--begin::Signin-->
        <div>
          <form
            class="form"
            id="kt_forgot_password_form"
            novalidate="novalidate"
          >
            <div class="pb-13 pt-lg-0 pt-5">
              <a href="#" class="text-center mb-10">
                <img
                  src="/media/svg/custom/logo-kemendagri-letter.svg"
                  class="max-h-70px"
                  alt=""
                />
              </a>
            </div>
            <div class="row d-flex justify-content-center mb-12 mt-12">
              <h1 class="text-dark font-weight-bold">Ubah Kata Sandi</h1>
            </div>
            <div class="form-group">
              <label class="font-size-h6 font-weight-bolder text-dark"
                >Kata Sandi Baru</label
              >
              <div
                id="example-input-group-1"
                label=""
                label-for="example-input-1"
              >
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                  type="password"
                  name="password"
                  ref="password"
                  placeholder="Kata Sandi"
                  v-model="form.password"
                  autocomplete="off"
                />
              </div>
            </div>
            <div class="form-group">
              <label class="font-size-h6 font-weight-bolder text-dark"
                >Ulangi Kata Sandi</label
              >
              <div
                id="example-input-group-2"
                label=""
                label-for="example-input-2"
              >
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                  type="password"
                  name="cpassword"
                  ref="cpassword"
                  placeholder="Ulangi Kata Sandi"
                  v-model="form.cpassword"
                  autocomplete="off"
                />
              </div>
            </div>
            <div class="pb-lg-0 pb-5">
              <button
                ref="kt_forgot_password_submit"
                class="btn btn-primary font-weight-bolder btn-block font-size-h6 px-15 py-4 my-3 mr-3"
                style="background-color: #18328D; color: #FFF; border-color: #18328D"
              >
                Kirim
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import KTUtil from "@/assets/js/components/util";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import Swal from "sweetalert2";
import TokenService from "@/core/services/token.service";
import { handleErrors } from "@/core/appUtil/util";
// import TokenService from "@/core/services/token.service";
// import { handleErrors } from "@/core/appUtil/util";

const swalSuccess = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-save",
    cancelButton: "btn btn-delete"
  },
  buttonsStyling: false
});

export default {
  name: "ForgotPassword",
  data() {
    return {
      form: {
        password: "",
        cpassword: ""
      },
      code: ""
    };
  },
  mounted() {
    this.code = this.$route.params.token;
    fetch(
      encodeURI(
        process.env.VUE_APP_URL_LOCAL +
          `/forgot-password/validate-code/${this.code}`
      ),
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "bearer " + TokenService.getLocalAccessToken()
        }
      }
    )
      .then(handleErrors)
      .then(response => response.json())
      .then(dataValid => {
        swalSuccess.fire({
          title: "Berhasil",
          text: "Kode Valid",
          icon: dataValid.status,
          showCancelButton: false,
          confirmButtonColor: "#18328D",
          confirmButtonText: "OK"
        });
      })
      .catch(error => {
        console.log(error);
        Swal.fire({
          title: "Kesalahan",
          text: "Kode tidak valid",
          icon: "error",
          showCancelButton: false,
          confirmButtonText: "Kembali ke Login",
          confirmButtonColor: "#18328D"
        }).then(result => {
          if (result.isConfirmed) {
            this.$router.push({ name: "login" });
          }
        });
      });
    const forgot_form = KTUtil.getById("kt_forgot_password_form");

    this.fv = formValidation(forgot_form, {
      fields: {
        password: {
          validators: {
            notEmpty: {
              message: "Kata Sandi Baru harus diisi"
            },
            stringLength: {
              min: 8,
              message: "Kata sandi minimal 8 karakter"
            },
            regexp: {
              regexp: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
              message: "Kata Sandi Baru harus berisi huruf besar, huruf kecil, angka, dan karakter spesial"
            }
          }
        },
        cpassword: {
          validators: {
            notEmpty: {
              message: "Ulangi Kata Sandi harus diisi"
            },
            identical: {
              compare: function() {
                return forgot_form.querySelector('[name="password"]').value;
              },
              message: "Kata Sandi tidak sama"
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });

    this.fv.on("core.form.valid", async () => {
      let password = this.form.password;
      let cpassword = this.form.cpassword;

      let data = {
        password: password,
        passwordConfirm: cpassword
      };

      await this.doChangePassword(data);
    });

    this.fv.on("core.form.invalid", () => {
      Swal.fire({
        title: "",
        text: "Silakan lengkapi data Anda!",
        icon: "error",
        confirmButtonColor: "#18328D",
        heightAuto: false
      });
    });
  },
  methods: {
    async doChangePassword(dataSubmit, attempt = 0) {
      attempt++;

      // set spinner to submit button
      const submitButton = this.$refs["kt_forgot_password_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      fetch(
        process.env.VUE_APP_URL_LOCAL +
          `/forgot-password/do-change/${this.code}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "bearer " + TokenService.getLocalAccessToken()
          },
          body: JSON.stringify(dataSubmit)
        }
      )
        .then(handleErrors)
        .then(response => response.json())
        .then(data => {
          if ((data.status == 'error' || data.data == null) && attempt <= 3) {
            this.doChangePassword(dataSubmit, attempt);

          } else {
            this.$router.push({ name: "login" });
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
          }
        })
        .catch(error => {
          console.log(error);
          Swal.fire({
            title: "Kesalahan",
            text: "Kode tidak valid",
            icon: "error",
            showCancelButton: false,
            confirmButtonText: "Kembali ke Login",
            confirmButtonColor: "#18328D"
          }).then(result => {
            if (result.isConfirmed) {
              this.$router.push({ name: "login" });
            }
          });
        });
    }
  }
};
</script>

<style scoped></style>
